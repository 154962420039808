<template>
	<div :class="isMobile ? 'container-fluid' : 'container'">
		<div class="spacer"></div>

		<!--		<div class="row alerts" v-if="profileSprite == null || profile.name === ''">-->
		<!--			<router-link class="col-12 mb-2" to="/profile/update">-->
		<!--				<div class="card p-2">-->
		<!--					<div class="alert alert-warning p-1 mb-0" v-if="profileSprite == null || profile.name === ''">-->
		<!--						Looks like you havent yet setup your profile, click here to get started!-->
		<!--					</div>-->
		<!--				</div>-->
		<!--			</router-link>-->

		<!--		</div>-->

		<div class="row" v-if="profileSprite == null || profile.name === ''">
			<ProfileUpdate :embedded="true"></ProfileUpdate>
		</div>

		<div class="row" v-show="$store.state.wallet.connected" v-else>
			<div :class="{'col-12': !!walletAddress, 'col-sm-12 col-md-8': !walletAddress}">
				<div class="card text-left h-100">
					<div class="row">
						<div class="col-md">
							<h2 class="mb-0">Welcome {{ profile.name ? profile.name : 'User' }} <small>
							</small></h2>
							<code v-show="!isWeb2 && !isMobile">{{ walletAddress || $store.state.wallet.address }}</code>
						</div>
						<div class="col-md-4">
							<span v-if="walletAddress === ''" class="small float-end">Last Login: <strong class="blue">{{
									lastLogin
								}}</strong></span>
							<button v-else class="btn btn-primary px-3 float-end">Message User</button>
						</div>
						<div class="col-md-auto">
							<router-link v-if="!walletAddress" to="/profile/update" class="btn btn-primary btn-sm bn sup">EDIT
							</router-link>
							<button @click="logout" class="btn btn-danger btn-sm bn sup">Logout</button>
						</div>
					</div>

					<div class="row" v-if="!isWeb2">
						<div class="col-4">
							<img class="img-fluid mt-2" :src="profile.avatar_url" v-if="profile.avatar_url">

							<div v-if="!profile.avatar_url" class="text-center">
								<p class="mt-5">No Profile Picture Set</p>
								<router-link v-if="!walletAddress" to="/profile/update" class="btn btn-primary btn-sm px-3">EDIT
								</router-link>
							</div>

						</div>
						<div class="col mt-3 mt-lg-0 text-left" v-if="!isWeb2">
							<table class="table table-responsive table-sm small">
								<tbody>
								<tr>
									<td>Time Online:</td>
									<td>00:00:00</td>
								</tr>
								<tr>
									<td>Global Ranking:</td>
									<td>-</td>
								</tr>
								</tbody>
								<tbody v-show="!isWeb2">
								<tr>
									<td>Total Net Worth:</td>
									<td><strong>$ {{ totalNetUSD }}</strong></td>
									<td><strong>{{ totalNet.toFixed(2) }} SOL</strong></td>
								</tr>
								<tr>
									<td>Tokens:</td>
									<td>$ {{ portfolioUSD.token_net }}</td>
									<td>{{ portfolio.token_net.toFixed(2) }} SOL</td>
								</tr>
								<tr>
									<td>Staked:</td>
									<td>$ {{ portfolioUSD.staked_net }}</td>
									<td>{{ portfolio.staked_net }} SOL</td>
								</tr>
								<tr>
									<td>NFT's: <small class="sup">(floor price)</small></td>
									<td>$ {{ portfolioUSD.nft_net }}</td>
									<td>{{ portfolio.nft_net }} SOL</td>
								</tr>
								</tbody>
							</table>
						</div>
					</div>

					<!--					NFTS-->
					<div class="row mt-3">
						<div class="col-12 collection-container">
							<div class="mt-3" v-if="loading && !isWeb2">
								<p>Loading...</p>
							</div>


							<div class="mt-3"
									v-if="ua !== null && ua.ownedNFT.length === 0 && !loading && $store.state.wallet.connected">
								<div class="text-center" v-if="walletAddress !== ''">
									<p>No Item's Owned, Check the Marketplace</p>
									<MarketplaceButton></MarketplaceButton>
								</div>
							</div>

							<div v-if="ua !== null">
								<UserNFTCollection :active="`0x0`" @add="onNFTClick" :nfts="ua.ownedNFT"></UserNFTCollection>
							</div>
						</div>
					</div>

				</div>
			</div>


			<div class="col-sm-12 col-md-4 mt-3 mt-md-0">
				<div class="card h-100">
					<div class="card-body">
						<div v-if="profileSprite !== null" class="text-center">
							<model-viewer camera-controls
									class="w-100"
									interaction-prompt-threshold="12000"
									:src="profileSprite" auto-rotate></model-viewer>

							<div class="row">
								<div class="col-sm-12 col-md-12 col-lg-6">
									<button class="btn btn-primary btn-block my-2" @click="createAvatar">EDIT AVATAR</button>
								</div>
								<div class="col-sm-12 col-md-12 col-lg-6">
									<router-link class="btn btn-secondary btn-block my-2" to="/profile/settings">SETTINGS</router-link>
								</div>
							</div>
						</div>

						<div v-if="profileSprite === null" class="text-center">
							<button class="btn btn-primary btn-sm mt-5" @click="createAvatar">CREATE AVATAR</button>
							<img class="w-75" alt="ready player me" src="/assets/images/rpm-badge-light-transparent.svg">
						</div>

						<div v-if="walletAddress === ''" class="d-flex flex-column align-items-center mt-2">
							<PhantomSign
									v-show="spriteUpdated"
									:disabled="!$store.state.wallet.connected"
									@sign="updateProfile"
									class="btn btn-block btn-primary mt-2"
							>
								Update
							</PhantomSign>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="row mt-4 mb-4">

			<!--LAND-->
			<div class="col-sm-12 col-lg-6">
				<div class="card h-100">
					<div class="card-header p-0">
						<div class="row">
							<div class="col">
								<router-link to="/land" class="text-white"><h1>Land</h1></router-link>
							</div>
						</div>
					</div>
					<div class="card-body">
						<div class="mt-3" v-if="!$store.state.wallet.connected">
							<p>Please login to view land</p>

							<!--							<Phantom class="btn btn-primary"></Phantom>-->
							<router-link to="/login" class="btn btn-primary">Login</router-link>
						</div>

						<div class="mt-3" v-if="loading">
							<p>Loading...</p>
						</div>

						<div class="mt-3"
								v-else-if="ua !== null && ua.ownedLand.length === 0 && !loading && $store.state.wallet.connected">

							<div v-if="walletAddress === ''">
								<p class="mt-3">No Land Owned</p>
								<MarketplaceButton v-if="!isWeb2" text="Buy On Marketplace"></MarketplaceButton>
							</div>

							<p v-else>User does not own any land</p>
						</div>

						<div v-if="(ua !== null && ua.ownedLand.length > 0)">

							<table class="table table-small text-white text-start">
								<thead>
								<tr class="text-center">
									<th>District</th>
									<th>Name</th>
									<th>Size</th>
									<th>Building</th>
									<th>Actions</th>
								</tr>
								</thead>
								<tbody>
								<LandRow :land="land" v-for="land in ua.ownedLand" :key="land.id"></LandRow>
								</tbody>
							</table>

							<!--							<div class="alert alert-info" v-for="land in ua.ownedLand" :key="land.id">-->
							<!--								<LandCard :land="land"></LandCard>-->
							<!--							</div>-->
						</div>
					</div>
				</div>
			</div>

			<!--BUILDINGS -->
			<div class="col-sm-12 col-lg-6 mt-3 mt-lg-0">
				<div class="card h-100">
					<div class="card-header p-0">
						<div class="row">
							<div class="col">
								<router-link to="/buildings" class="text-white"><h1>Buildings</h1></router-link>
							</div>
							<div class="col-auto" v-if="ua !== null && ua.ownedLand.length > 0">
								<router-link to="/buildings/create" class="btn btn-primary btn-sm btn-block px-4">NEW</router-link>
							</div>
						</div>
					</div>
					<div class="card-body">
						<div class="mt-3" v-if="!$store.state.wallet.connected">
							<p>Please login to view buildings</p>
							<router-link to="/login" class="btn btn-primary">Login</router-link>
						</div>

						<div class="mt-3" v-if="loading">
							<p>Loading...</p>
						</div>

						<div class="mt-3"
								v-else-if="ua !== null && buildings.length === 0 && !loading && $store.state.wallet.connected">

							<div v-if="walletAddress === ''">
								<p>No Buildings Owned</p>
<!--								<router-link to="/land/0x0/create" class="btn btn-primary mt-3">Create Metaverse</router-link>-->
							</div>

							<p v-else>User does not own any land</p>
						</div>

						<div v-if="(ua !== null && buildings.length > 0)">

							<table class="table table-small text-white text-start">
								<thead>
								<tr class="text-start">
									<th>Logo</th>
									<th>Name</th>
									<th>District</th>
									<th>Sector</th>
									<th colspan="2">Actions</th>
								</tr>
								</thead>
								<tbody>
								<BuildingRow :building="building" v-for="building in buildings" :key="building.token_addr"></BuildingRow>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>

			<!--			Metaverses-->
			<div class="col-sm-12 mt-3">
				<div class="card">
					<div class="card-header p-0">
						<div class="row">
							<div class="col">
								<router-link to="/metaverse" class="text-white"><h1>Metaverse</h1></router-link>
							</div>
							<div class="col-auto">
								<router-link to="/land/0x0/create" class="btn btn-primary btn-sm btn-block px-4">NEW</router-link>
							</div>
						</div>
					</div>
					<div class="card-body">
						<div class="mt-3" v-if="!$store.state.wallet.connected">
							<p>Please login to view</p>

							<!--							<Phantom class="btn btn-primary"></Phantom>-->
							<router-link to="/login" class="btn btn-primary">Login</router-link>
						</div>

						<div class="mt-3" v-if="loading">
							<p>Loading...</p>
						</div>

						<div v-else-if="metaverseInstances.length === 0">

							<div v-if="walletAddress === ''">
								<p>No Metaverse Created</p>
								<router-link to="/land/0x0/create" class="btn btn-primary mt-3">Create Metaverse</router-link>
							</div>

							<p v-else>User does not own any metaverse</p>
						</div>

						<div v-if="metaverseInstances.length > 0">

							<table class="table table-small text-white text-start">
								<thead>
								<tr class="text-start">
									<th>Oasis</th>
									<th>Building</th>
									<th colspan="3">Actions</th>
								</tr>
								</thead>
								<tbody>
<!--								<OasisRow :land="land" v-for="land in ua.ownedLand" :key="land.id"></OasisRow>-->
								<OasisRow :land="metaverse" v-for="(metaverse,key) in metaverseInstances" :key="`${metaverse.id}-${key}`"></OasisRow>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>

			<div v-if="loadRooms" class="col-sm-12 col-lg-6 mt-3">
				<div class="card">
					<div class="card-header p-0">
						<div class="row">
							<div class="col">
								<router-link to="/buildings" class="text-white"><h1>Rooms</h1></router-link>
							</div>
							<div class="col-auto">
								<!--								<router-link to="/land/0x0/create" class="btn btn-primary btn-sm btn-block px-4">NEW</router-link>-->
							</div>
						</div>
					</div>
					<div class="card-body">
						<table class="table table-small text-white text-start">
							<thead>
							<tr>
								<th>Name</th>
								<th>Category</th>
								<th>Capacity</th>
								<th>Building</th>
								<th colspan="2">Actions</th>
							</tr>
							</thead>
							<tbody>
							<RoomRow :room="room" v-for="room in rooms" :key="room.id"></RoomRow>
							</tbody>
						</table>
					</div>
				</div>
			</div>


		</div>
		<div v-if="showSpriteSelector" id="avatar-creator"
				style="position: fixed; top: 0; left: 0; bottom: 0; right: 0; background-color: white; padding-top: 70px; z-index: 100;">
			<button @click="showSpriteSelector = !showSpriteSelector" style="position: absolute; right: 0; top: 70px;">X
			</button>
			<iframe src="https://alphabatem.readyplayer.me/avatar?frameApi" id="frame" class="frame"
					allow="camera *; microphone *" style="width: 100%; height: 100%;"></iframe>
		</div>
	</div>
</template>

<script>
import Portfolio from "../../js/api/portfolio";
import {UserAssets} from "../../js/util/user_assets";
import Tooltip from "../../components/common/Tooltip";
import User from '../../js/api/user'
import PortfolioCalculator from "../../js/util/portfolio";
import ShowSingleNFT from "../../components/editor/actions/ShowSingleNFT";
import UserNFTCollection from "../../components/nft/UserNFTCollection";
import Auth from '../../js/api/auth'
import PhantomSign from "../../components/wallet/PhantomSign";
import LandListIndex from "../land/LandListIndex";
import LandRow from "../../components/dashboard/LandRow";
import ProfileUpdate from "./ProfileUpdate";
import OasisRow from "../../components/dashboard/OasisRow";
import MarketplaceButton from "../../components/misc/MarketplaceButton";
import RoomRow from "../../components/dashboard/RoomRow";
import {useWallet} from "@alphabatem/vue2-wallet-adapter";
import BuildingRow from "../../components/dashboard/BuildingRow";

//TODO Load land NFT's from Solana

// Sapphire - 8LbQwLa8oWAc6zDsQQEj4sFyJXGM1sUjgaPfxa2mGWE8

export default {
	name: "UserDashboard",
	components: {BuildingRow, RoomRow, MarketplaceButton, OasisRow, ProfileUpdate, LandRow, LandListIndex, UserNFTCollection, ShowSingleNFT, Tooltip, PhantomSign},
	props: {
		walletAddress: {
			type: String,
			default: () => '',
		},
	},
	data() {
		return {
			loadRooms: false,
			showSpriteSelector: false,
			spriteUpdated: false,
			profile: {},
			loading: 0,
			ua: null,
			rooms: [],
			metaverseInstances: [],
			buildings: [],
			stats: {
				total_visits: 0,
				daily_visits: 0,
				total_time: 0,
				total_referrals: 0,
			},
			solPrice: 0,
			portfolio: {
				total_net: 0,
				token_net: 0,
				nft_net: 0,
				staked_net: 0,
			}
		};
	},
	watch: {
		'$store.state.wallet.address'() {

			if (this.walletAddress) return

			this.getProfile();
			if (this.ua)
				this.ua.getAccountInfo(this.$store.state.wallet.address, () => {
					// this.getPortfolio();
				})
		}
	},
	computed: {
		isMobile: function () {
			return this.$store.state.settings.isMobile
		},

		isWeb2: function () {
			return this.$store.state.wallet.address.indexOf("Web2") !== -1
		},

		totalNetUSD: function () {
			return (this.totalNet * this.solPrice).toFixed(2);
		},

		totalNet: function () {
			return parseFloat(this.portfolio.token_net) + parseFloat(this.portfolio.nft_net) + parseFloat(this.portfolio.staked_net);
		},

		portfolioUSD: function () {
			return {
				token_net: (this.portfolio.token_net * this.solPrice).toFixed(2),
				nft_net: (this.portfolio.nft_net * this.solPrice).toFixed(2),
				staked_net: (this.portfolio.staked_net * this.solPrice).toFixed(2),
			}
		},

		lastLogin: function () {
			return this.profile.last_login_at ? this.profile.last_login_at : this.$store.state.wallet.connected ? 'Just Now' : '-';
		},
		profileSprite: function () {
			return this.profile.sprite_url !== "" ? this.profile.sprite_url : null;
		},
	},
	methods: {

		logout(e) {
			this.$router.push("/")

			if (!this.$store.state.wallet.isWeb3) {
				this.$store.commit('wallet/clear_wallet');
				return
			}

			this.$store.commit('wallet/clear_wallet');
			useWallet().disconnect().then(() => {
				console.log("Logged out")
			})
		},

		createAvatar() {
			this.showSpriteSelector = true
			console.log('create avatar')

			window.addEventListener('message', this.subscribe)
		},

		subscribe(event) {
			const json = this.parse(event);
			const frame = document.getElementById('frame');

			// Susbribe to all events sent from Ready Player Me once frame is ready
			if (json?.eventName === 'v1.frame.ready' && json?.origin === 'readyplayerme') {
				frame.contentWindow.postMessage(
						JSON.stringify({
							target: 'readyplayerme',
							type: 'subscribe',
							eventName: 'v1.**'
						}),
						'*'
				);
				return;
			}

			// Get avatar GLB URL
			if (!json && event.origin === 'https://alphabatem.readyplayer.me') {
				console.log(`Avatar URL: ${event.data}`);
				this.profile.sprite_url = `${event.data}?cache=${new Date().getTime()}`
				this.showSpriteSelector = false
				this.spriteUpdated = true

				if (this.isWeb2) {
					this.updateProfile(this.signWeb2())
				}

				window.removeEventListener('message', this.subscribe)
			}
		},

		parse(event) {
			try {
				return JSON.parse(event.data);
			} catch (error) {
				return null;
			}
		},

		updateProfile(sig) {
			//Web3ProfileUpdate
			const payload = {
				public_address: this.$store.state.wallet.address,
				signed: sig,
				name: this.profile.name,
				avatar_url: this.profile.avatar_url,
				sprite_url: this.profile.sprite_url.split("?")[0],
			}

			Auth.update(this.$store.state.wallet.address, payload).then(resp => {
				console.log('Profile updated', resp.data)
				// this.$router.push('/profile/dashboard')
			})

			this.spriteUpdated = false
		},

		signWeb2() {
			const enc = new TextEncoder()
			const data = enc.encode(this.$store.state.wallet.jwt_token)

			const d = []
			for (let i = 0; i < data.length; i++) {
				d.push(data[i])
			}

			return {
				publicKey: this.$store.state.wallet.address,
				signature: {
					data: d,
					type: "JWT"
				},
			}
		},

		getProfile() {
			User.show(this.walletAddress || this.$store.state.wallet.address).then((resp) => {
				this.profile = resp.data
				this.$store.commit("profile/set_profile", this.profile)
			})
		},

		//TODO update with ABTM Explorer
		onNFTClick(nft) {
			window.open(`https://explorer.solana.com/address/${nft.id}`, "_blank").focus();
		},

		getRooms() {
			if (!this.loadRooms)
				return

			User.indexRooms(this.walletAddress || this.$store.state.wallet.address).then(response => {
				this.rooms = response.data;
			});
		},

		getMetaverseInstances() {
			User.indexMetaverse(this.walletAddress || this.$store.state.wallet.address).then(response => {
				this.metaverseInstances = response.data.sort((a,b) => a.created_at > b.created_at);
			});
		},

		getBuildings() {
			User.indexBuildings(this.walletAddress || this.$store.state.wallet.address).then(response => {
				console.log("getBuildings", response.data)
				this.buildings = response.data.sort((a,b) => a.created_at > b.created_at);
			});
		},

		getPortfolio() {
			this.getRooms()
			this.getMetaverseInstances()
			this.getBuildings()

			if (this.isWeb2)
				return this.getWeb2Portfolio()

			return this.getWeb3Portfolio()
		},


		getWeb2Portfolio() {
			if (!this.isWeb2)
				return

			this.loading = false
		},

		getWeb3Portfolio() {
			if (this.isWeb2)
				return

			if (!this.ua) {
				this.loading = false;
				return
			}


			this.ua.getBalance(this.walletAddress || this.$store.state.wallet.address).then((resp) => {
				this.portfolio.token_net = resp * 0.000000001;
			});

			console.log("Getting portfolio")
			const nfts = this.ua.ownedNFT.map(nft => nft.id)
			const calc = new PortfolioCalculator();

			Portfolio.tokenCollections("magic_eden_svc", nfts).then((resp) => {
				console.log("ME,", resp.data)

				const netWorth = calc.calculateNetWorth(nfts, resp.data)
				console.log("Net Worth", netWorth)
				this.portfolio.nft_net = netWorth.toFixed(2)
			}).catch(() => {
				this.loading = false;
			}).finally(() => {
				this.loading = false;
			})
		},

		async getOnChainData() {
			if (this.ua.ownedNFT.length > 0) {
				this.getPortfolio();
				return //Already populated
			}

			this.ua.getAccountInfo(this.walletAddress || this.$store.state.wallet.address, () => {
				this.getPortfolio();
			})
		}
	},
	mounted() {
		this.ua =  this.$store.state.profile.userAssets;
		Portfolio.getSolPrice().then((resp) => {
			console.log("Sol Price", resp)
			this.solPrice = resp.data.solana.usd;
		});

		if (this.$store.state.wallet.connected) {
			this.loading = true;
			this.getProfile();

			if (this.isWeb2) {
				this.getPortfolio()
				return
			}

			this.getOnChainData()
		}

	},
	beforeDestroy() {
		this.ua = null
	},
	destroyed() {
		window.removeEventListener('message', this.subscribe)
	},
}
</script>

<style scoped>

h1 {
	text-align: left;
	font-size: 1.5em;
}

.assignment {
	font-size: 1.2em;
	font-weight: bold;
}

.stats h2 {
	text-align: left;
}

.card-header span {
	font-size: 1.2rem;
	font-weight: 500;
	line-height: 1.2;
}

.stats h4 {
	font-size: 3em;
}

.alert.alert-info {
	text-align: left;
	color: white;
	background-color: rgba(255, 255, 255, 0.05);
	cursor: pointer;
}

.alert.alert-info:hover {
	background-color: rgba(255, 255, 255, 0.2);
}

img {
	max-height: 200px;
}


model-viewer {
	width: 100%;
	height: 650px;
}

td {
	color: white;
	border-bottom: none;
}

a {
	text-decoration: none;
}

.collection-container {
	max-height: 300px;
	overflow-y: scroll;
}

</style>

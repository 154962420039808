<template>
	<div class="overlay manifest-editor">
		<div class="row">
			<div class="col-md-2 col-sm-3 col-lg-1 text-center p-1 pe-0">
				<div class="card p-0">
					<div class="card-body p-1">
						<div class="info mb-3 mt-3">
							<img v-if="metaverse.logo_src !== ''" v-show="image_loaded" :src="metaverse.logo_src" @loaded="image_loaded = true" class="img-100 logo-img">
							<h4 v-if="metaverse.logo_src === '' || !image_loaded" class="text-center">{{ metaverse.name }}</h4>
						</div>

						<hr>


						<router-link class="btn text-uppercase btn-block my-2 btn-primary btn-sm"
								:to="`edit/?debug=true`">Visual Editor
						</router-link>

						<hr>

						<button
								:class="`btn btn-sm text-uppercase btn-block mt-2 ${isActive('settings') ?  'btn-secondary' : 'btn-outline-primary'}`"
								@click="active.menu = 'settings'; active.item_id = null">general
						</button>

						<hr>

						<!--				TODO REMOVE-->
						<button
								:class="`btn btn-sm text-uppercase btn-block mt-2 ${isActive('items') ?  'btn-secondary' : 'btn-outline-primary'}`"
								@click="active.menu = 'items'; active.item_id = null">items
						</button>
						<button
								:class="`btn btn-sm text-uppercase btn-block mt-2 ${isActive('models') ?  'btn-secondary' : 'btn-outline-primary'}`"
								@click="active.menu = 'models'; active.item_id = null">uploads
						</button>

						<hr>

						<button
								:class="`btn btn-sm text-uppercase btn-block mt-2 ${isActive('terrain') ?  'btn-secondary' : 'btn-outline-primary'}`"
								@click="active.menu = 'terrain'; active.item_id = null">terrain
						</button>
						<button
								:class="`btn btn-sm text-uppercase btn-block mt-2 ${isActive('entities') ?  'btn-secondary' : 'btn-outline-primary'}`"
								@click="active.menu = 'entities'; active.item_id = null">entities
						</button>
						<button
								:class="`btn btn-sm text-uppercase btn-block mt-2 ${isActive('characters') ?  'btn-secondary' : 'btn-outline-primary'}`"
								@click="active.menu = 'characters'; active.item_id = null">characters
						</button>
						<button
								:class="`btn btn-sm text-uppercase btn-block mt-2 ${isActive('lighting') ?  'btn-secondary' : 'btn-outline-primary'}`"
								@click="active.menu = 'lighting'; active.item_id = null">lighting
						</button>
						<button
							:class="`btn btn-sm text-uppercase btn-block mt-2 ${isActive('sound') ?  'btn-secondary' : 'btn-outline-primary'}`"
							@click="active.menu = 'sound'; active.item_id = null">sounds
						</button>
						<!--				<button class="btn btn-sm text-uppercase btn-block mt-2 btn-outline-primary"-->
						<!--								@click="active.menu = 'action_list'">actions-->
						<!--				</button>-->
						<button
								:class="`btn btn-sm text-nowrap text-uppercase btn-block mt-2 ${isActive('environment') ?  'btn-secondary' : 'btn-outline-primary'}`"
								@click="active.menu = 'environment'; active.item_id = null">environment
						</button>
						<!--				<button class="btn btn-sm text-uppercase btn-block mt-2 btn-outline-primary"-->
						<!--								@click="active.menu = 'post_processing'">filters-->
						<!--				</button>-->

						<hr>

						<PhantomSign @sign="updateManifest"
								:class="`btn btn-sm text-uppercase btn-block ${draftInProgress ? 'btn-warning' : 'O'}`">
							Save
						</PhantomSign>

						<button class="btn btn-sm text-uppercase btn-block btn-outline-success mt-2"
								@click="joinMetaverse">Play Now
						</button>
					</div>
				</div>
			</div>
			<div class="col-lg-11 col-md-10 col-sm-9" v-if="!$store.state.metaverse.loading">
				<div class="card">
					<div class="card-body">
						<EditorMenu
							:active-menu="active"
							:manifest="manifest"
							@select="select"
						></EditorMenu>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Vue from 'vue'
import { mapActions } from "vuex"

import EditorMenu from "../../components/editor/EditorMenu";
import PhantomSign from "../../components/wallet/PhantomSign";

export default Vue.extend({
	name: "ManifestEditor",
	components: {PhantomSign, EditorMenu},
	data() {
		return {
			draftInProgress: false,
			draft: {},
			image_loaded: false,
			active: {
				menu: "settings",
				item_id: "",
			},
			metaverse: {},
			manifest: {},
		}
	},
	methods: {
		...mapActions({
			getMetaverse: 'metaverse/getMetaverse',
			updateMetaverse: 'metaverse/updateMetaverse',
		}),

		select(options) {
			this.active = options
		},

		isActive: function (key) {
			return this.active.menu === key
		},
		
		updateManifest: async function (signature) {
			if (signature === '' || !signature) {
				this.$toastr.e('Please sign the manifest before saving.');
				return
			}

			try {
				await this.updateMetaverse(signature)
				
				this.$toastr.s("Manifest updated");
			} catch (e) {
				console.error(e)
				this.$toastr.e("Error updating manifest");
			}
		},

		joinMetaverse: function (e) {
			e.preventDefault()
			// this.$router.push(`/${this.$route.params.id}/visit`)
			this.$router.push(`/metaverse/${this.$route.params.id}/visit`)
		},

	},
	async mounted() {
		try {
			await this.getMetaverse(this.$route.params.id)
		} catch (e) {
			console.error(e)
		}
	}
})
</script>

<style scoped>
.overlay {
	position: fixed;
	overflow-y: scroll;
	top: 70px;
	padding-top: 10px;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(0, 0, 0, 0.5);
}

.btn-secondary {
	background-color: unset;
	border-radius: 9px;
}

code.manifest {
	font-family: "Source Code Pro", monospace;
	font-size: 14px;
	color: #fff;
	padding: 10px;
	white-space: pre-wrap;
	overflow-x: auto;
}

.img-100 {
	width: 80%;
	margin-left: 10%;
}

.btn-lg {
	font-size: 1em;
}
</style>

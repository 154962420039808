var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "spacer mt-3" }),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "metaverse-create mb-5" },
      [_c("CreateMetaverseWizard")],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
	<div :class="`${embedded ? 'px-0' : 'container'}`">
		<div v-if="!embedded" class="spacer"></div>
		<div class="row mb-3">
			<div class="col-12">
				<div class="card py-2">
					<div class="card-body py-0 row">
						<div class="col">
							<h3 class="text-uppercase">Update Profile</h3>
						</div>
						<div class="col-auto">
							<router-link to="/profile/dashboard" class="btn btn-secondary btn-sm bn sup">BACK</router-link>
							<button @click="logout" class="btn btn-danger btn-sm bn sup">Logout</button>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="row mt-2">
			<div class="col-8">

				<div class="card h-100">
					<div class="card-body">

						<form class="form text-start">
							<label class="mb-0 pb-0">Username<sup class="text-danger">*</sup></label>
							<input required class="form-control mt-0" type="text" v-model="profile.name" placeholder="Username">
						</form>

						<hr class="">
						<div class="row my-4" v-if="profileValid">
							<div class="col-4 text-start">
								<label>Profile Image</label>
								<p class="mt-4 text-center" v-show="!profile.avatar_url"><i>No PFP Set</i></p>
								<img class="img-fluid" v-show="profile.avatar_url" :src="profile.avatar_url" alt="Avatar image">
								<!--							<input type="file" class="form-control-file" v-model="form.avatar_url">-->
							</div>
							<div class="col-8 text-left">
								<div class="profile-img-container">
									<UserNFTCollection @add="onProfileSelected"></UserNFTCollection>
								</div>
							</div>
						</div>
					</div>
					<div class="card-footer">

						<div class="tasks my-2">

							<p class="text-start">To start playing, complete the following items:</p>

							<div class="row my-1">
								<div v-show="nameValid" class="col-auto"><i class="fa task-indicator fa-check bg-primary"></i></div>
								<div v-show="!nameValid" class="col-auto"><i class="fa task-indicator fa-xmark bg-danger"></i></div>
								<div class="col text-start">Select a Username</div>
							</div>

							<div class="row my-1">
								<div v-show="avatarValid" class="col-auto"><i class="fa task-indicator fa-check bg-primary"></i></div>
								<div v-show="!avatarValid" class="col-auto"><i class="fa task-indicator fa-xmark bg-danger"></i></div>
								<div class="col text-start">Create Avatar</div>
							</div>

						</div>


						<PhantomSign :disabled="!profileValid" @sign="updateProfile"
								class="btn btn-block btn-primary btn-glow mt-2">Update Profile
						</PhantomSign>

						<p v-if="!profileValid" class="fw-bold text-danger">In order to access Hourglass you will need a name and an avatar!</p>
					</div>
				</div>
			</div>
			<div class="col">

				<div class="card h-100">
					<div class="card-body text-start">
						<label>Avatar<sup class="text-danger">*</sup></label>

						<p class="mt-5 text-center" v-show="!profile.sprite_url"><i>No Avatar Set</i></p>
						<div v-if="spriteLoading" class="col-12 mt-5">
							<i class="fa fa-spinner fa-spin"></i> <span>Loading Avatar...</span>
						</div>
						<model-viewer v-if="profileSprite && !spriteLoading" :src="profileSprite" auto-rotate></model-viewer>

					</div>

					<div class="card-footer">
						<div class="row">
							<div class="col p-0">
								<button class="btn btn-primary btn-block mt-3" @click="createAvatar">{{ profile.sprite_url ? "Update" : "Create" }} Avatar</button>
							</div>
							<img class="col-3 p-0" alt="ready player me" src="/assets/images/rpm-badge-light-transparent.svg">
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="row mt-3" v-if="profileValid">
			<div class="col-12">
				<div class="card">
					<div class="card-body">
						<router-link to="/metaverse" class="btn btn-secondary btn-block">Continue To Game</router-link>
					</div>
				</div>
			</div>
		</div>

		<div class="guilds" v-if="false">
			<h1 class="mb-3">Guild</h1>
			<div class="row my-5">
				<div class="col-lg-8 offset-lg-2 col-md-12 col-sm-12 text-center">
					<h2 class="my-5">No Current Guild</h2>
					<button class="btn btn-primary" v-on:click="leaveGuild" disabled>Leave Guild</button>
				</div>
			</div>
			<hr/>
		</div>

		<div v-if="showSpriteSelector"
				style="position: fixed; top: 0; left: 0; bottom: 0; right: 0; background-color: white; padding-top: 70px; z-index: 100;">
			<button @click="showSpriteSelector = !showSpriteSelector" style="position: absolute; right: 0; top: 70px;">X
			</button>
			<iframe src="https://alphabatem.readyplayer.me/avatar?frameApi" id="frame" class="frame"
					allow="camera *; microphone *" style="width: 100%; height: 100%;"></iframe>
		</div>
	</div>
</template>

<script>
import Auth from '../../js/api/auth'
import User from '../../js/api/user'
import ModelUploader from "../../components/upload/ModelUploader";
import "@google/model-viewer"
import PhantomSign from "../../components/wallet/PhantomSign";
import UserNFTCollection from "../../components/nft/UserNFTCollection";
import {useWallet} from "@alphabatem/vue2-wallet-adapter";

export default {
	components: {UserNFTCollection, PhantomSign, ModelUploader},
	props: {
		embedded: {
			type: Boolean,
			default: false,
		}
	},
	data() {
		return {
			showSpriteSelector: false,
			spriteLoading: false,
			user: '',
			profile: {
				name: '',
				referred_by: '',
				sprite_url: null,
			},
		}
	},
	watch: {
		'$store.state.wallet.address'() {
			this.getProfile()
		}
	},
	computed: {
		hasReferral() {
			return this.profile.referred_by && this.profile.referred_by !== ''
		},
		profileSprite: function () {
			return this.profile.sprite_url ? this.profile.sprite_url : null;
		},
		profileValid: function () {
			return this.nameValid && this.avatarValid
		},
		nameValid: function () {
			return this.profile.name.length >= 3
		},
		avatarValid: function () {
			return this.profile.sprite_url && this.profile.sprite_url !== ''
		}
	},
	methods: {

		logout(e) {
			this.$router.push("/")

			if (!this.$store.state.wallet.isWeb3) {
				this.$store.commit('wallet/clear_wallet');
				return
			}

			this.$store.commit('wallet/clear_wallet');
			useWallet().disconnect().then(() => {
				console.log("Logged out")
			})
		},

		createAvatar() {
			this.showSpriteSelector = true
			console.log('create avatar')

			window.addEventListener('message', this.subscribe)
		},

		subscribe(event) {
			const json = this.parse(event);
			const frame = document.getElementById('frame');

			// Susbribe to all events sent from Ready Player Me once frame is ready
			if (json?.eventName === 'v1.frame.ready' && json?.origin === 'readyplayerme') {
				frame.contentWindow.postMessage(
						JSON.stringify({
							target: 'readyplayerme',
							type: 'subscribe',
							eventName: 'v1.**'
						}),
						'*'
				);
				return;
			}

			// Get avatar GLB URL
			if (!json && event.origin === 'https://alphabatem.readyplayer.me') {
				console.log(`Avatar URL: ${event.data}`);

				this.showSpriteSelector = false
				this.spriteLoading = true
				this.profile.sprite_url = `${event.data}?cache=${new Date().getTime()}`
				this.spriteUpdated = true

				setTimeout(() => {
					this.spriteLoading = false
				}, 3000)


				window.removeEventListener('message', this.subscribe)
			}
		},

		parse(event) {
			try {
				return JSON.parse(event.data);
			} catch (error) {
				return null;
			}
		},

		getProfile() {
			User.show(this.$store.state.wallet.address).then(resp => {
				this.profile = resp.data
			})
		},

		updateProfile(sig) {
			//Web3ProfileUpdate
			const payload = {
				public_address: this.$store.state.wallet.address,
				signed: sig,
				name: this.profile.name,
				avatar_url: this.profile.avatar_url,
				sprite_url: this.profile.sprite_url.split("?")[0],
			}

			Auth.update(this.$store.state.wallet.address, payload).then(resp => {
				console.log('Profile updated', resp.data)
				this.$toastr.s("Profile Updated!")
				this.$router.push('/profile/dashboard')
			})
		},

		onProfileSelected(e) {
			console.log("onProfileUpload", e)
			this.profile.avatar_url = e.poster_uri
		},
		onAvatarUpload(e) {
			console.log("onAvatarUpload", e)
			this.profile.sprite_url = e
		},

		leaveGuild(e) {
			e.preventDefault()
			Auth.leaveGuild(this.$store.state.wallet.address).then(resp => {
				console.log('Profile updated', resp.data)
				this.$router.push('/profile')
			})
		},
	},
	mounted() {
		if (this.$store.state.wallet.connected) {
			this.getProfile()
		}
	},
	destroyed() {
		window.removeEventListener('message', this.subscribe)
	},
}
</script>

<style scoped>
form {
	color: white;
}

.btn,
h2 {
	color: white;
}

img {
	max-height: 200px;
}

model-viewer {
	width: 100%;
	height: 300px;
}

.profile-img-container {
	max-height: 300px;
	overflow-y: scroll;
}

.btn-glow:not(:disabled) {
	transition: all 0.3s ease-in-out;
	box-shadow: #ffffff 0 0 13px;
}

.task-indicator {
	width: 20px;
	height: 20px;
}
</style>

<template>
	<div class="container">
		<div class="spacer mt-3"></div>
		<div class="metaverse-create mb-5">

			<CreateMetaverseWizard></CreateMetaverseWizard>
		</div>
	</div>
</template>

<script>
import CreateMetaverseWizard from "../../components/create-wizard/CreateMetaverseWizard";

export default {
	name: "MetaverseCreate",
	components: {CreateMetaverseWizard}
}
</script>

<style scoped>
h3 {
	text-align: left;
	font-weight: bold;
	text-transform: uppercase;
}
</style>
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "overlay manifest-editor" }, [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-md-2 col-sm-3 col-lg-1 text-center p-1 pe-0" },
        [
          _c("div", { staticClass: "card p-0" }, [
            _c(
              "div",
              { staticClass: "card-body p-1" },
              [
                _c("div", { staticClass: "info mb-3 mt-3" }, [
                  _vm.metaverse.logo_src !== ""
                    ? _c("img", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.image_loaded,
                            expression: "image_loaded",
                          },
                        ],
                        staticClass: "img-100 logo-img",
                        attrs: { src: _vm.metaverse.logo_src },
                        on: {
                          loaded: function ($event) {
                            _vm.image_loaded = true
                          },
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.metaverse.logo_src === "" || !_vm.image_loaded
                    ? _c("h4", { staticClass: "text-center" }, [
                        _vm._v(_vm._s(_vm.metaverse.name)),
                      ])
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("hr"),
                _vm._v(" "),
                _c(
                  "router-link",
                  {
                    staticClass:
                      "btn text-uppercase btn-block my-2 btn-primary btn-sm",
                    attrs: { to: `edit/?debug=true` },
                  },
                  [_vm._v("Visual Editor\n\t\t\t\t\t")]
                ),
                _vm._v(" "),
                _c("hr"),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    class: `btn btn-sm text-uppercase btn-block mt-2 ${
                      _vm.isActive("settings")
                        ? "btn-secondary"
                        : "btn-outline-primary"
                    }`,
                    on: {
                      click: function ($event) {
                        _vm.active.menu = "settings"
                        _vm.active.item_id = null
                      },
                    },
                  },
                  [_vm._v("general\n\t\t\t\t\t")]
                ),
                _vm._v(" "),
                _c("hr"),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    class: `btn btn-sm text-uppercase btn-block mt-2 ${
                      _vm.isActive("items")
                        ? "btn-secondary"
                        : "btn-outline-primary"
                    }`,
                    on: {
                      click: function ($event) {
                        _vm.active.menu = "items"
                        _vm.active.item_id = null
                      },
                    },
                  },
                  [_vm._v("items\n\t\t\t\t\t")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    class: `btn btn-sm text-uppercase btn-block mt-2 ${
                      _vm.isActive("models")
                        ? "btn-secondary"
                        : "btn-outline-primary"
                    }`,
                    on: {
                      click: function ($event) {
                        _vm.active.menu = "models"
                        _vm.active.item_id = null
                      },
                    },
                  },
                  [_vm._v("uploads\n\t\t\t\t\t")]
                ),
                _vm._v(" "),
                _c("hr"),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    class: `btn btn-sm text-uppercase btn-block mt-2 ${
                      _vm.isActive("terrain")
                        ? "btn-secondary"
                        : "btn-outline-primary"
                    }`,
                    on: {
                      click: function ($event) {
                        _vm.active.menu = "terrain"
                        _vm.active.item_id = null
                      },
                    },
                  },
                  [_vm._v("terrain\n\t\t\t\t\t")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    class: `btn btn-sm text-uppercase btn-block mt-2 ${
                      _vm.isActive("entities")
                        ? "btn-secondary"
                        : "btn-outline-primary"
                    }`,
                    on: {
                      click: function ($event) {
                        _vm.active.menu = "entities"
                        _vm.active.item_id = null
                      },
                    },
                  },
                  [_vm._v("entities\n\t\t\t\t\t")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    class: `btn btn-sm text-uppercase btn-block mt-2 ${
                      _vm.isActive("characters")
                        ? "btn-secondary"
                        : "btn-outline-primary"
                    }`,
                    on: {
                      click: function ($event) {
                        _vm.active.menu = "characters"
                        _vm.active.item_id = null
                      },
                    },
                  },
                  [_vm._v("characters\n\t\t\t\t\t")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    class: `btn btn-sm text-uppercase btn-block mt-2 ${
                      _vm.isActive("lighting")
                        ? "btn-secondary"
                        : "btn-outline-primary"
                    }`,
                    on: {
                      click: function ($event) {
                        _vm.active.menu = "lighting"
                        _vm.active.item_id = null
                      },
                    },
                  },
                  [_vm._v("lighting\n\t\t\t\t\t")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    class: `btn btn-sm text-uppercase btn-block mt-2 ${
                      _vm.isActive("sound")
                        ? "btn-secondary"
                        : "btn-outline-primary"
                    }`,
                    on: {
                      click: function ($event) {
                        _vm.active.menu = "sound"
                        _vm.active.item_id = null
                      },
                    },
                  },
                  [_vm._v("sounds\n\t\t\t\t\t")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    class: `btn btn-sm text-nowrap text-uppercase btn-block mt-2 ${
                      _vm.isActive("environment")
                        ? "btn-secondary"
                        : "btn-outline-primary"
                    }`,
                    on: {
                      click: function ($event) {
                        _vm.active.menu = "environment"
                        _vm.active.item_id = null
                      },
                    },
                  },
                  [_vm._v("environment\n\t\t\t\t\t")]
                ),
                _vm._v(" "),
                _c("hr"),
                _vm._v(" "),
                _c(
                  "PhantomSign",
                  {
                    class: `btn btn-sm text-uppercase btn-block ${
                      _vm.draftInProgress ? "btn-warning" : "O"
                    }`,
                    on: { sign: _vm.updateManifest },
                  },
                  [_vm._v("\n\t\t\t\t\t\tSave\n\t\t\t\t\t")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass:
                      "btn btn-sm text-uppercase btn-block btn-outline-success mt-2",
                    on: { click: _vm.joinMetaverse },
                  },
                  [_vm._v("Play Now\n\t\t\t\t\t")]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      !_vm.$store.state.metaverse.loading
        ? _c("div", { staticClass: "col-lg-11 col-md-10 col-sm-9" }, [
            _c("div", { staticClass: "card" }, [
              _c(
                "div",
                { staticClass: "card-body" },
                [
                  _c("EditorMenu", {
                    attrs: {
                      "active-menu": _vm.active,
                      manifest: _vm.manifest,
                    },
                    on: { select: _vm.select },
                  }),
                ],
                1
              ),
            ]),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
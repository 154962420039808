import { render, staticRenderFns } from "./UserDashboard.vue?vue&type=template&id=6ba685d9&scoped=true&"
import script from "./UserDashboard.vue?vue&type=script&lang=js&"
export * from "./UserDashboard.vue?vue&type=script&lang=js&"
import style0 from "./UserDashboard.vue?vue&type=style&index=0&id=6ba685d9&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ba685d9",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/build/repo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6ba685d9')) {
      api.createRecord('6ba685d9', component.options)
    } else {
      api.reload('6ba685d9', component.options)
    }
    module.hot.accept("./UserDashboard.vue?vue&type=template&id=6ba685d9&scoped=true&", function () {
      api.rerender('6ba685d9', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/profile/UserDashboard.vue"
export default component.exports
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: `${_vm.embedded ? "px-0" : "container"}` }, [
    !_vm.embedded ? _c("div", { staticClass: "spacer" }) : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "row mb-3" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("div", { staticClass: "card py-2" }, [
          _c("div", { staticClass: "card-body py-0 row" }, [
            _vm._m(0),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-auto" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "btn btn-secondary btn-sm bn sup",
                    attrs: { to: "/profile/dashboard" },
                  },
                  [_vm._v("BACK")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-danger btn-sm bn sup",
                    on: { click: _vm.logout },
                  },
                  [_vm._v("Logout")]
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row mt-2" }, [
      _c("div", { staticClass: "col-8" }, [
        _c("div", { staticClass: "card h-100" }, [
          _c("div", { staticClass: "card-body" }, [
            _c("form", { staticClass: "form text-start" }, [
              _vm._m(1),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.profile.name,
                    expression: "profile.name",
                  },
                ],
                staticClass: "form-control mt-0",
                attrs: { required: "", type: "text", placeholder: "Username" },
                domProps: { value: _vm.profile.name },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.profile, "name", $event.target.value)
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _c("hr", {}),
            _vm._v(" "),
            _vm.profileValid
              ? _c("div", { staticClass: "row my-4" }, [
                  _c("div", { staticClass: "col-4 text-start" }, [
                    _c("label", [_vm._v("Profile Image")]),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.profile.avatar_url,
                            expression: "!profile.avatar_url",
                          },
                        ],
                        staticClass: "mt-4 text-center",
                      },
                      [_c("i", [_vm._v("No PFP Set")])]
                    ),
                    _vm._v(" "),
                    _c("img", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.profile.avatar_url,
                          expression: "profile.avatar_url",
                        },
                      ],
                      staticClass: "img-fluid",
                      attrs: {
                        src: _vm.profile.avatar_url,
                        alt: "Avatar image",
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-8 text-left" }, [
                    _c(
                      "div",
                      { staticClass: "profile-img-container" },
                      [
                        _c("UserNFTCollection", {
                          on: { add: _vm.onProfileSelected },
                        }),
                      ],
                      1
                    ),
                  ]),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "card-footer" },
            [
              _c("div", { staticClass: "tasks my-2" }, [
                _c("p", { staticClass: "text-start" }, [
                  _vm._v("To start playing, complete the following items:"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row my-1" }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.nameValid,
                          expression: "nameValid",
                        },
                      ],
                      staticClass: "col-auto",
                    },
                    [
                      _c("i", {
                        staticClass: "fa task-indicator fa-check bg-primary",
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.nameValid,
                          expression: "!nameValid",
                        },
                      ],
                      staticClass: "col-auto",
                    },
                    [
                      _c("i", {
                        staticClass: "fa task-indicator fa-xmark bg-danger",
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "col text-start" }, [
                    _vm._v("Select a Username"),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row my-1" }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.avatarValid,
                          expression: "avatarValid",
                        },
                      ],
                      staticClass: "col-auto",
                    },
                    [
                      _c("i", {
                        staticClass: "fa task-indicator fa-check bg-primary",
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.avatarValid,
                          expression: "!avatarValid",
                        },
                      ],
                      staticClass: "col-auto",
                    },
                    [
                      _c("i", {
                        staticClass: "fa task-indicator fa-xmark bg-danger",
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "col text-start" }, [
                    _vm._v("Create Avatar"),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "PhantomSign",
                {
                  staticClass: "btn btn-block btn-primary btn-glow mt-2",
                  attrs: { disabled: !_vm.profileValid },
                  on: { sign: _vm.updateProfile },
                },
                [_vm._v("Update Profile\n\t\t\t\t\t")]
              ),
              _vm._v(" "),
              !_vm.profileValid
                ? _c("p", { staticClass: "fw-bold text-danger" }, [
                    _vm._v(
                      "In order to access Hourglass you will need a name and an avatar!"
                    ),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col" }, [
        _c("div", { staticClass: "card h-100" }, [
          _c(
            "div",
            { staticClass: "card-body text-start" },
            [
              _vm._m(2),
              _vm._v(" "),
              _c(
                "p",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.profile.sprite_url,
                      expression: "!profile.sprite_url",
                    },
                  ],
                  staticClass: "mt-5 text-center",
                },
                [_c("i", [_vm._v("No Avatar Set")])]
              ),
              _vm._v(" "),
              _vm.spriteLoading
                ? _c("div", { staticClass: "col-12 mt-5" }, [
                    _c("i", { staticClass: "fa fa-spinner fa-spin" }),
                    _vm._v(" "),
                    _c("span", [_vm._v("Loading Avatar...")]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.profileSprite && !_vm.spriteLoading
                ? _c("model-viewer", {
                    attrs: { src: _vm.profileSprite, "auto-rotate": "" },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "card-footer" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col p-0" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary btn-block mt-3",
                    on: { click: _vm.createAvatar },
                  },
                  [
                    _vm._v(
                      _vm._s(_vm.profile.sprite_url ? "Update" : "Create") +
                        " Avatar"
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("img", {
                staticClass: "col-3 p-0",
                attrs: {
                  alt: "ready player me",
                  src: "/assets/images/rpm-badge-light-transparent.svg",
                },
              }),
            ]),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _vm.profileValid
      ? _c("div", { staticClass: "row mt-3" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("div", { staticClass: "card" }, [
              _c(
                "div",
                { staticClass: "card-body" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "btn btn-secondary btn-block",
                      attrs: { to: "/metaverse" },
                    },
                    [_vm._v("Continue To Game")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    false
      ? _c("div", { staticClass: "guilds" }, [
          _c("h1", { staticClass: "mb-3" }, [_vm._v("Guild")]),
          _vm._v(" "),
          _c("div", { staticClass: "row my-5" }, [
            _c(
              "div",
              {
                staticClass:
                  "col-lg-8 offset-lg-2 col-md-12 col-sm-12 text-center",
              },
              [
                _c("h2", { staticClass: "my-5" }, [_vm._v("No Current Guild")]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    attrs: { disabled: "" },
                    on: { click: _vm.leaveGuild },
                  },
                  [_vm._v("Leave Guild")]
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("hr"),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.showSpriteSelector
      ? _c(
          "div",
          {
            staticStyle: {
              position: "fixed",
              top: "0",
              left: "0",
              bottom: "0",
              right: "0",
              "background-color": "white",
              "padding-top": "70px",
              "z-index": "100",
            },
          },
          [
            _c(
              "button",
              {
                staticStyle: { position: "absolute", right: "0", top: "70px" },
                on: {
                  click: function ($event) {
                    _vm.showSpriteSelector = !_vm.showSpriteSelector
                  },
                },
              },
              [_vm._v("X\n\t\t")]
            ),
            _vm._v(" "),
            _c("iframe", {
              staticClass: "frame",
              staticStyle: { width: "100%", height: "100%" },
              attrs: {
                src: "https://alphabatem.readyplayer.me/avatar?frameApi",
                id: "frame",
                allow: "camera *; microphone *",
              },
            }),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col" }, [
      _c("h3", { staticClass: "text-uppercase" }, [_vm._v("Update Profile")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "mb-0 pb-0" }, [
      _vm._v("Username"),
      _c("sup", { staticClass: "text-danger" }, [_vm._v("*")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", [
      _vm._v("Avatar"),
      _c("sup", { staticClass: "text-danger" }, [_vm._v("*")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.isMobile ? "container-fluid" : "container" }, [
    _c("div", { staticClass: "spacer" }),
    _vm._v(" "),
    _vm.profileSprite == null || _vm.profile.name === ""
      ? _c(
          "div",
          { staticClass: "row" },
          [_c("ProfileUpdate", { attrs: { embedded: true } })],
          1
        )
      : _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.$store.state.wallet.connected,
                expression: "$store.state.wallet.connected",
              },
            ],
            staticClass: "row",
          },
          [
            _c(
              "div",
              {
                class: {
                  "col-12": !!_vm.walletAddress,
                  "col-sm-12 col-md-8": !_vm.walletAddress,
                },
              },
              [
                _c("div", { staticClass: "card text-left h-100" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-md" }, [
                      _c("h2", { staticClass: "mb-0" }, [
                        _vm._v(
                          "Welcome " +
                            _vm._s(
                              _vm.profile.name ? _vm.profile.name : "User"
                            ) +
                            " "
                        ),
                        _c("small"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "code",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.isWeb2 && !_vm.isMobile,
                              expression: "!isWeb2 && !isMobile",
                            },
                          ],
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.walletAddress ||
                                _vm.$store.state.wallet.address
                            )
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-md-4" }, [
                      _vm.walletAddress === ""
                        ? _c("span", { staticClass: "small float-end" }, [
                            _vm._v("Last Login: "),
                            _c("strong", { staticClass: "blue" }, [
                              _vm._v(_vm._s(_vm.lastLogin)),
                            ]),
                          ])
                        : _c(
                            "button",
                            { staticClass: "btn btn-primary px-3 float-end" },
                            [_vm._v("Message User")]
                          ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-md-auto" },
                      [
                        !_vm.walletAddress
                          ? _c(
                              "router-link",
                              {
                                staticClass: "btn btn-primary btn-sm bn sup",
                                attrs: { to: "/profile/update" },
                              },
                              [_vm._v("EDIT\n\t\t\t\t\t\t\t")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-danger btn-sm bn sup",
                            on: { click: _vm.logout },
                          },
                          [_vm._v("Logout")]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  !_vm.isWeb2
                    ? _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-4" }, [
                          _vm.profile.avatar_url
                            ? _c("img", {
                                staticClass: "img-fluid mt-2",
                                attrs: { src: _vm.profile.avatar_url },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.profile.avatar_url
                            ? _c(
                                "div",
                                { staticClass: "text-center" },
                                [
                                  _c("p", { staticClass: "mt-5" }, [
                                    _vm._v("No Profile Picture Set"),
                                  ]),
                                  _vm._v(" "),
                                  !_vm.walletAddress
                                    ? _c(
                                        "router-link",
                                        {
                                          staticClass:
                                            "btn btn-primary btn-sm px-3",
                                          attrs: { to: "/profile/update" },
                                        },
                                        [_vm._v("EDIT\n\t\t\t\t\t\t\t\t")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]),
                        _vm._v(" "),
                        !_vm.isWeb2
                          ? _c(
                              "div",
                              { staticClass: "col mt-3 mt-lg-0 text-left" },
                              [
                                _c(
                                  "table",
                                  {
                                    staticClass:
                                      "table table-responsive table-sm small",
                                  },
                                  [
                                    _vm._m(0),
                                    _vm._v(" "),
                                    _c(
                                      "tbody",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: !_vm.isWeb2,
                                            expression: "!isWeb2",
                                          },
                                        ],
                                      },
                                      [
                                        _c("tr", [
                                          _c("td", [
                                            _vm._v("Total Net Worth:"),
                                          ]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _c("strong", [
                                              _vm._v(
                                                "$ " + _vm._s(_vm.totalNetUSD)
                                              ),
                                            ]),
                                          ]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _c("strong", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.totalNet.toFixed(2)
                                                ) + " SOL"
                                              ),
                                            ]),
                                          ]),
                                        ]),
                                        _vm._v(" "),
                                        _c("tr", [
                                          _c("td", [_vm._v("Tokens:")]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              "$ " +
                                                _vm._s(
                                                  _vm.portfolioUSD.token_net
                                                )
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.portfolio.token_net.toFixed(
                                                  2
                                                )
                                              ) + " SOL"
                                            ),
                                          ]),
                                        ]),
                                        _vm._v(" "),
                                        _c("tr", [
                                          _c("td", [_vm._v("Staked:")]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              "$ " +
                                                _vm._s(
                                                  _vm.portfolioUSD.staked_net
                                                )
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(_vm.portfolio.staked_net) +
                                                " SOL"
                                            ),
                                          ]),
                                        ]),
                                        _vm._v(" "),
                                        _c("tr", [
                                          _vm._m(1),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              "$ " +
                                                _vm._s(_vm.portfolioUSD.nft_net)
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(_vm.portfolio.nft_net) +
                                                " SOL"
                                            ),
                                          ]),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "row mt-3" }, [
                    _c("div", { staticClass: "col-12 collection-container" }, [
                      _vm.loading && !_vm.isWeb2
                        ? _c("div", { staticClass: "mt-3" }, [
                            _c("p", [_vm._v("Loading...")]),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.ua !== null &&
                      _vm.ua.ownedNFT.length === 0 &&
                      !_vm.loading &&
                      _vm.$store.state.wallet.connected
                        ? _c("div", { staticClass: "mt-3" }, [
                            _vm.walletAddress !== ""
                              ? _c(
                                  "div",
                                  { staticClass: "text-center" },
                                  [
                                    _c("p", [
                                      _vm._v(
                                        "No Item's Owned, Check the Marketplace"
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("MarketplaceButton"),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.ua !== null
                        ? _c(
                            "div",
                            [
                              _c("UserNFTCollection", {
                                attrs: { active: `0x0`, nfts: _vm.ua.ownedNFT },
                                on: { add: _vm.onNFTClick },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]),
                  ]),
                ]),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "col-sm-12 col-md-4 mt-3 mt-md-0" }, [
              _c("div", { staticClass: "card h-100" }, [
                _c("div", { staticClass: "card-body" }, [
                  _vm.profileSprite !== null
                    ? _c(
                        "div",
                        { staticClass: "text-center" },
                        [
                          _c("model-viewer", {
                            staticClass: "w-100",
                            attrs: {
                              "camera-controls": "",
                              "interaction-prompt-threshold": "12000",
                              src: _vm.profileSprite,
                              "auto-rotate": "",
                            },
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              { staticClass: "col-sm-12 col-md-12 col-lg-6" },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn btn-primary btn-block my-2",
                                    on: { click: _vm.createAvatar },
                                  },
                                  [_vm._v("EDIT AVATAR")]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-sm-12 col-md-12 col-lg-6" },
                              [
                                _c(
                                  "router-link",
                                  {
                                    staticClass:
                                      "btn btn-secondary btn-block my-2",
                                    attrs: { to: "/profile/settings" },
                                  },
                                  [_vm._v("SETTINGS")]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.profileSprite === null
                    ? _c("div", { staticClass: "text-center" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-primary btn-sm mt-5",
                            on: { click: _vm.createAvatar },
                          },
                          [_vm._v("CREATE AVATAR")]
                        ),
                        _vm._v(" "),
                        _c("img", {
                          staticClass: "w-75",
                          attrs: {
                            alt: "ready player me",
                            src: "/assets/images/rpm-badge-light-transparent.svg",
                          },
                        }),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.walletAddress === ""
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "d-flex flex-column align-items-center mt-2",
                        },
                        [
                          _c(
                            "PhantomSign",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.spriteUpdated,
                                  expression: "spriteUpdated",
                                },
                              ],
                              staticClass: "btn btn-block btn-primary mt-2",
                              attrs: {
                                disabled: !_vm.$store.state.wallet.connected,
                              },
                              on: { sign: _vm.updateProfile },
                            },
                            [_vm._v("\n\t\t\t\t\t\t\t\tUpdate\n\t\t\t\t\t\t\t")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
              ]),
            ]),
          ]
        ),
    _vm._v(" "),
    _c("div", { staticClass: "row mt-4 mb-4" }, [
      _c("div", { staticClass: "col-sm-12 col-lg-6" }, [
        _c("div", { staticClass: "card h-100" }, [
          _c("div", { staticClass: "card-header p-0" }, [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col" },
                [
                  _c(
                    "router-link",
                    { staticClass: "text-white", attrs: { to: "/land" } },
                    [_c("h1", [_vm._v("Land")])]
                  ),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "card-body" }, [
            !_vm.$store.state.wallet.connected
              ? _c(
                  "div",
                  { staticClass: "mt-3" },
                  [
                    _c("p", [_vm._v("Please login to view land")]),
                    _vm._v(" "),
                    _c(
                      "router-link",
                      {
                        staticClass: "btn btn-primary",
                        attrs: { to: "/login" },
                      },
                      [_vm._v("Login")]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.loading
              ? _c("div", { staticClass: "mt-3" }, [
                  _c("p", [_vm._v("Loading...")]),
                ])
              : _vm.ua !== null &&
                _vm.ua.ownedLand.length === 0 &&
                !_vm.loading &&
                _vm.$store.state.wallet.connected
              ? _c("div", { staticClass: "mt-3" }, [
                  _vm.walletAddress === ""
                    ? _c(
                        "div",
                        [
                          _c("p", { staticClass: "mt-3" }, [
                            _vm._v("No Land Owned"),
                          ]),
                          _vm._v(" "),
                          !_vm.isWeb2
                            ? _c("MarketplaceButton", {
                                attrs: { text: "Buy On Marketplace" },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _c("p", [_vm._v("User does not own any land")]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.ua !== null && _vm.ua.ownedLand.length > 0
              ? _c("div", [
                  _c(
                    "table",
                    { staticClass: "table table-small text-white text-start" },
                    [
                      _vm._m(2),
                      _vm._v(" "),
                      _c(
                        "tbody",
                        _vm._l(_vm.ua.ownedLand, function (land) {
                          return _c("LandRow", {
                            key: land.id,
                            attrs: { land: land },
                          })
                        }),
                        1
                      ),
                    ]
                  ),
                ])
              : _vm._e(),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-sm-12 col-lg-6 mt-3 mt-lg-0" }, [
        _c("div", { staticClass: "card h-100" }, [
          _c("div", { staticClass: "card-header p-0" }, [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col" },
                [
                  _c(
                    "router-link",
                    { staticClass: "text-white", attrs: { to: "/buildings" } },
                    [_c("h1", [_vm._v("Buildings")])]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.ua !== null && _vm.ua.ownedLand.length > 0
                ? _c(
                    "div",
                    { staticClass: "col-auto" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "btn btn-primary btn-sm btn-block px-4",
                          attrs: { to: "/buildings/create" },
                        },
                        [_vm._v("NEW")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "card-body" }, [
            !_vm.$store.state.wallet.connected
              ? _c(
                  "div",
                  { staticClass: "mt-3" },
                  [
                    _c("p", [_vm._v("Please login to view buildings")]),
                    _vm._v(" "),
                    _c(
                      "router-link",
                      {
                        staticClass: "btn btn-primary",
                        attrs: { to: "/login" },
                      },
                      [_vm._v("Login")]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.loading
              ? _c("div", { staticClass: "mt-3" }, [
                  _c("p", [_vm._v("Loading...")]),
                ])
              : _vm.ua !== null &&
                _vm.buildings.length === 0 &&
                !_vm.loading &&
                _vm.$store.state.wallet.connected
              ? _c("div", { staticClass: "mt-3" }, [
                  _vm.walletAddress === ""
                    ? _c("div", [_c("p", [_vm._v("No Buildings Owned")])])
                    : _c("p", [_vm._v("User does not own any land")]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.ua !== null && _vm.buildings.length > 0
              ? _c("div", [
                  _c(
                    "table",
                    { staticClass: "table table-small text-white text-start" },
                    [
                      _vm._m(3),
                      _vm._v(" "),
                      _c(
                        "tbody",
                        _vm._l(_vm.buildings, function (building) {
                          return _c("BuildingRow", {
                            key: building.token_addr,
                            attrs: { building: building },
                          })
                        }),
                        1
                      ),
                    ]
                  ),
                ])
              : _vm._e(),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-sm-12 mt-3" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-header p-0" }, [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col" },
                [
                  _c(
                    "router-link",
                    { staticClass: "text-white", attrs: { to: "/metaverse" } },
                    [_c("h1", [_vm._v("Metaverse")])]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-auto" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "btn btn-primary btn-sm btn-block px-4",
                      attrs: { to: "/land/0x0/create" },
                    },
                    [_vm._v("NEW")]
                  ),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "card-body" }, [
            !_vm.$store.state.wallet.connected
              ? _c(
                  "div",
                  { staticClass: "mt-3" },
                  [
                    _c("p", [_vm._v("Please login to view")]),
                    _vm._v(" "),
                    _c(
                      "router-link",
                      {
                        staticClass: "btn btn-primary",
                        attrs: { to: "/login" },
                      },
                      [_vm._v("Login")]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.loading
              ? _c("div", { staticClass: "mt-3" }, [
                  _c("p", [_vm._v("Loading...")]),
                ])
              : _vm.metaverseInstances.length === 0
              ? _c("div", [
                  _vm.walletAddress === ""
                    ? _c(
                        "div",
                        [
                          _c("p", [_vm._v("No Metaverse Created")]),
                          _vm._v(" "),
                          _c(
                            "router-link",
                            {
                              staticClass: "btn btn-primary mt-3",
                              attrs: { to: "/land/0x0/create" },
                            },
                            [_vm._v("Create Metaverse")]
                          ),
                        ],
                        1
                      )
                    : _c("p", [_vm._v("User does not own any metaverse")]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.metaverseInstances.length > 0
              ? _c("div", [
                  _c(
                    "table",
                    { staticClass: "table table-small text-white text-start" },
                    [
                      _vm._m(4),
                      _vm._v(" "),
                      _c(
                        "tbody",
                        _vm._l(
                          _vm.metaverseInstances,
                          function (metaverse, key) {
                            return _c("OasisRow", {
                              key: `${metaverse.id}-${key}`,
                              attrs: { land: metaverse },
                            })
                          }
                        ),
                        1
                      ),
                    ]
                  ),
                ])
              : _vm._e(),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _vm.loadRooms
        ? _c("div", { staticClass: "col-sm-12 col-lg-6 mt-3" }, [
            _c("div", { staticClass: "card" }, [
              _c("div", { staticClass: "card-header p-0" }, [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "text-white",
                          attrs: { to: "/buildings" },
                        },
                        [_c("h1", [_vm._v("Rooms")])]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-auto" }),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "card-body" }, [
                _c(
                  "table",
                  { staticClass: "table table-small text-white text-start" },
                  [
                    _vm._m(5),
                    _vm._v(" "),
                    _c(
                      "tbody",
                      _vm._l(_vm.rooms, function (room) {
                        return _c("RoomRow", {
                          key: room.id,
                          attrs: { room: room },
                        })
                      }),
                      1
                    ),
                  ]
                ),
              ]),
            ]),
          ])
        : _vm._e(),
    ]),
    _vm._v(" "),
    _vm.showSpriteSelector
      ? _c(
          "div",
          {
            staticStyle: {
              position: "fixed",
              top: "0",
              left: "0",
              bottom: "0",
              right: "0",
              "background-color": "white",
              "padding-top": "70px",
              "z-index": "100",
            },
            attrs: { id: "avatar-creator" },
          },
          [
            _c(
              "button",
              {
                staticStyle: { position: "absolute", right: "0", top: "70px" },
                on: {
                  click: function ($event) {
                    _vm.showSpriteSelector = !_vm.showSpriteSelector
                  },
                },
              },
              [_vm._v("X\n\t\t\t")]
            ),
            _vm._v(" "),
            _c("iframe", {
              staticClass: "frame",
              staticStyle: { width: "100%", height: "100%" },
              attrs: {
                src: "https://alphabatem.readyplayer.me/avatar?frameApi",
                id: "frame",
                allow: "camera *; microphone *",
              },
            }),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tbody", [
      _c("tr", [
        _c("td", [_vm._v("Time Online:")]),
        _vm._v(" "),
        _c("td", [_vm._v("00:00:00")]),
      ]),
      _vm._v(" "),
      _c("tr", [
        _c("td", [_vm._v("Global Ranking:")]),
        _vm._v(" "),
        _c("td", [_vm._v("-")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", [
      _vm._v("NFT's: "),
      _c("small", { staticClass: "sup" }, [_vm._v("(floor price)")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", { staticClass: "text-center" }, [
        _c("th", [_vm._v("District")]),
        _vm._v(" "),
        _c("th", [_vm._v("Name")]),
        _vm._v(" "),
        _c("th", [_vm._v("Size")]),
        _vm._v(" "),
        _c("th", [_vm._v("Building")]),
        _vm._v(" "),
        _c("th", [_vm._v("Actions")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", { staticClass: "text-start" }, [
        _c("th", [_vm._v("Logo")]),
        _vm._v(" "),
        _c("th", [_vm._v("Name")]),
        _vm._v(" "),
        _c("th", [_vm._v("District")]),
        _vm._v(" "),
        _c("th", [_vm._v("Sector")]),
        _vm._v(" "),
        _c("th", { attrs: { colspan: "2" } }, [_vm._v("Actions")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", { staticClass: "text-start" }, [
        _c("th", [_vm._v("Oasis")]),
        _vm._v(" "),
        _c("th", [_vm._v("Building")]),
        _vm._v(" "),
        _c("th", { attrs: { colspan: "3" } }, [_vm._v("Actions")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Name")]),
        _vm._v(" "),
        _c("th", [_vm._v("Category")]),
        _vm._v(" "),
        _c("th", [_vm._v("Capacity")]),
        _vm._v(" "),
        _c("th", [_vm._v("Building")]),
        _vm._v(" "),
        _c("th", { attrs: { colspan: "2" } }, [_vm._v("Actions")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
<template>
	<div class="container-fluid">
		<div class="spacer"></div>

		<div class="card p-0">
			<div class="card-body p-1">
				<div class="row">
					<div class="col-8 text-start"><h1 class="mb-0 mt-2">Land</h1></div>
					<div class="col-md-3 col-sm-11">
						<input class="form-control" v-model="search" placeholder="Search via Name, Address or ID">
					</div>
					<div class="col-1 pt-2">
						<button class="btn btn-primary">Search</button>
					</div>
				</div>
			</div>
		</div>


		<div class="card p-0 mt-2">
			<div class="card-body p-0">
				<div class="room-table mt-3">
					<table class="table">
						<thead>
						<th :key="h.name" v-for="(h) in headers" :colspan="h.span" :class="h.class">{{ h.name }}</th>
						</thead>
						<tbody>
						<tr :key="r" v-for="(row,r) in filteredData">
							<!--                        <td><a :href="`https://opensea.io/assets/${row.saga}/${row.id}`">{{row.id.substring(row.id.length - 16)}}</a></td>-->
							<td>
								<SectorMap :center="row" :extra-tiles="[]" :tiles="data" :mapSize="3"
										:tile-length="1"></SectorMap>
							</td>
							<td>{{ row.token_addr }}</td>
							<td>{{ row.x }}, {{ row.y }}</td>
							<td>{{ row.Buildings != null ? row.Buildings[0].name : '-' }}</td>
							<td>{{ row.purchased_at ? row.purchased_at : row.created_at }}</td>
							<td class="text-center">
								<router-link class="btn btn-primary btn-sm mx-2" :to="`/map/land/${row.x}/${row.y}`">View</router-link>
								<router-link v-if="row.Buildings == null" class="btn btn-primary btn-sm mx-2" :to="`/buildings/create`">Build</router-link>
								<router-link class="btn btn-secondary btn-sm mx-2" :to="`/map/land/${row.token_addr}/visit`">Visit</router-link>
							</td>
						</tr>
						</tbody>
					</table>
				</div>
				<div class="row">
					<div v-on:click="pagination.offset -= pagination.limit" class="col text-left">
						<button class="btn btn-primary btn-sm" v-show="pagination.offset > 0">PREVIOUS</button>
					</div>
					<div v-on:click="pagination.offset += pagination.limit" class="col text-right">
						<button class="btn btn-primary btn-sm">NEXT</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
// import Land from "@/js/api/land"
import User from "../../js/api/user"
import SectorMap from "../../components/land/map/SectorMap";

export default {
	name: "LandListIndex",
	components: {SectorMap},
	data() {
		return {
			headers: [
				{name: "Location", span: 1, class: ""},
				{name: "ID", span: 1, class: ""},
				{name: "Coords", span: 1, class: ""},
				{name: "Active Building", span: 1, class: ""},
				{name: "Purchase Date", span: 1, class: ""},
				{name: "Actions", span: 2, class: "text-center"}
			],
			pagination: {
				limit: 25,
				offset: 0,
			},
			data: [],
			search: "",
		}
	},
	watch: {
		search: function () {
			this.pagination.offset = 0;
		}
	},
	computed: {
		filteredData() {
			if (this.search === "")
				return this.data.slice(this.pagination.offset, this.pagination.offset + this.pagination.limit);

			return this.data.filter(row => {
				return this.search.includes(row.x) ||
						this.search.includes(row.y);
				// || this.search.includes(row.token_addr)
			}).slice(this.pagination.offset, this.pagination.limit)
		}
	},
	methods: {
		getTableData: function () {
			User.indexLand(this.$store.state.wallet.address).then((r) => this.data = r.data);
		},
	},
	mounted() {
		this.getTableData();
	}
}
</script>

<style scoped>

table {
	color: white;
}

thead {
	font-weight: bold;
}

td {
	vertical-align: middle;
}

.btn + .btn:last-child {
	margin-left: 0;
}
</style>
